// Footer.scss //

@import '../../Styles/variables.scss';

.footer {
  width: 100%;
  height: 150px;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  .footer__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    h3 {
      color: lighten($btn-hover, 20%);
      padding: 0;
      margin: .5rem;
    }
      a {
        background-color: $btn;
        height: 30px;
        width: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        text-decoration: none;
        transition: background-color .3s ease-in-out;
        font-size: .8rem;
        border: solid .8px black;
        &:hover {
          background-color: $btn-hover;
          color: white;
        }
      }
      p {
        color: lighten($btn-hover,  20%);
        margin: .3rem;
        font-size: .8rem;
      }
    }
  }


  @media screen and (max-width: 700px) {
    .footer {
      .footer__container {
        h3 {
          font-size: .8rem;
        }
        a {
          font-size: .8rem;
        }
        p {
          font-size: .8rem;
        }
      }
    } 
    
  }

  @media screen and (max-width: 400px) {
    .footer {
      .footer__container {
        h3 {
          font-size: .6rem;
        }
        a {
          font-size: .6rem;
        }
        p {
          font-size: .6rem;
        }
      }
    } 
    
  }
    
  