/* About.css */

@import '../../Styles/variables.scss';

.about {
  height: auto;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  overflow: hidden;
  justify-content: center;
  max-width: 1200px;

  .about__container {
    padding: 2rem;
    width: 50%;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    .about__image {
      height: 100%;
      text-align: center;
      line-height: .5;
      position: relative;
      h2 {
        opacity: 1;
        font-size: 1.5rem;
        font-weight: 500;
      }

      h3 {
        color: grey;
        font-size: 1rem;
        font-weight: 300;
      }

      img {
        width: 200px;
        
        border-radius: 50%;
        margin: 1rem;
        align-self: flex-start;
        overflow: hidden;
      }
    }

    .about__text{
      line-height: 1.5;
      margin-left: 2rem;
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}

.about__skills-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 5rem 0  0 8rem;

  .about__skills-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 0;
    }
    .about__skills-image {
      width: 75px;
      height: 75px;
      margin: 1rem;
      align-self: flex-start;
    }
  }


}

@media screen and (max-width: 1150px) {
 .about {
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100vh;
   .about__container {
    display: flex;
    justify-content: space-around;
     width: 100%;
     margin: 0;
     .about__image {
       img {
         width: 150px;
         height: 150px;
       }
     }
     .about__text {
      width: 40rem;
       margin: 0;
     }
   }

      .about__skills-container {
        margin: 0;
        grid-template-columns: repeat(7, 1fr);

      .about__skills-image {
        width: 50px;
        height: 50px;
     }

  }
 }
}

@media screen and (max-width: 950px) {
  .about {
    .about__container {
      margin: 0;
      padding: 0;
      .about__text {
        width: 30rem;
      }
    }
    .about__skills-container {
      grid-template-columns: repeat(4, 1fr);
      .about__skills-image {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media  screen and (max-width: 700px) {
  .about {
    margin-top: 5rem;
    height: auto;
    .about__container {
      flex-direction: column;
      .about__text {
        margin: 2rem 0;
        padding: 2rem;
        border: $border;
      }
    }
    .about__skills-container {
      margin: 2rem 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .about {
    margin-top: 5rem;
    height: auto;
    .about__container {
      flex-direction: column;
      .about__text {
        margin: 2rem 0;
        padding: 2rem;
        border: $border;
        overflow-y: scroll;
        width: 70%;
        height: 50vh;
        font-size: .8rem;
      }
    }
    .about__skills-container {
      margin: 2rem 0;

      .about__skills-image {
        width: 25px;
        height: 25px;
      }
      .about__skills-image-text {
        font-size: .5rem;
      }
    }
  }
}

