/* Intro.css */

.intro {
  height: 100vh;
  margin: 0; /* Reset default body margin */
  padding: 0; /* Reset default body padding */
  background-image: url("https://ucarecdn.com/97a397ab-4086-40c6-94a1-89c163eebc42/-/resize/3000x/");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro__container {
  background-color: white;
  height: 30%;
  width: 45%;
  max-width: 500px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  border-radius: 10px;
  padding: 4rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: auto;
  }
  p {
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
  }
}





.intro__button {
  margin: 1rem 0 1rem 0;
  padding: .5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}

.intro__button:hover {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 700px) {
  .intro {
    .intro__container {
      padding: 4rem;
      h1 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  
}

@media screen and (max-width: 550px) {
  .intro {
    .intro__container {
      width: 80%;
      padding: 2rem;
      h1 {
        font-size: 1.5rem;
      }
      p {
        font-size: .8rem;
      }
    }
  }
}
  
