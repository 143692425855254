/* Projects.css */

.projects {
  min-height: 40vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 1.5rem;
    align-self: flex-start;
    margin-left: 1rem;
    font-weight: 500;
  }
}


.projects__container {
  width: 85vw;
  max-width: 1200px;
  // min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .projects__item-container {
    display: flex;
    max-width: 100%;

    .projects__item {
      margin: 1rem;
      width: 25rem;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      a {
        display: block;
      }
      .projects__item-image {
        max-width: 100%;
        cursor: pointer;
        border-radius: 5px;
        transition: ease-in-out 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
      h3 {
        margin: 0;
        padding: 0.5rem;
        text-align: center;
        background-color: #fff;
        color: white;
        font-size: 1.2rem;
        color: black;
        font-weight: 200;
        transition: ease-in-out 0.3s;
        cursor: pointer;
      }
    }
  }
}

.projects__project-details {
  height: 100%
}

@media screen and (max-width: 950px) {
  .projects {
    .projects__container {
      .projects__item-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        .projects__item {
          width: 90%;
  
        }
      }
    }
  }
  
}

@media screen and (max-width: 550px) {
  .projects {
    .projects__container {
      h1 {
        align-self: center;
        font-size: 1.5rem;
        margin-top: 3rem;
      }
      .projects__item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .projects__item {
          width: 70%;
        }
      }
    }
  }
}