/* ProjectDetails.css */

.project-details {
  max-width: 1600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ease-in-out 0.3s;
  .project-details__header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    h1 {
    margin: 2rem 0 0 0;
    }
    .project-details__links {
      width: 50%;
      margin: 0.5rem;
    }    
  }
  .project-details__container {
    width: 85%;
    display: flex;
    border: solid rgb(208, 206, 206) 1px;

    .project-details__left {
      width: 50%;

      .project-details__image-container {
        display: flex;
        justify-content: center;
        border-radius: 10px;
        padding: 0.5rem;
        margin: 0.5rem;

        .project-details__image {
          width: 400px;
          margin: 0.5rem;
        }
      }
      .project-details__tech {
        border-radius: 10px;
        padding: 0.5rem;
        margin: 0.5rem;

        ul {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 0;
        }
        
        .project-details__tech-used {
          height: 80%;
          width: 100%;

          p {
            list-style: none;
            margin: 0.5rem;
            padding: 0.8rem;
            border-radius: 5px;
            background-color: #656464;
            color: #fff;
          }
        }
      }
    }

    .project-details__right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      border-radius: 10px;
      padding: 0 1rem;
      margin: 0.5rem;
      .project-details__summary {
        margin: 1rem;
        padding: 1rem;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .project-details__summary-text {
          line-height: 2;
        }
      }
    }
  }
}

@media  screen and (max-width: 950px) {
  .project-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .project-details__header {
      font-size: .8rem;
    }
    .project-details__container {
      flex-direction: column;
      .project-details__left {
        width: 100%;
        .project-details__tech {
          ul {
            justify-content: center;
          }
        }
      }
      .project-details__right {
        padding: 0;
        margin: 0;
        width: 100%;
        .project-details__summary {
          width: 100%;
          overflow-y: scroll;
          .project-details__summary-text {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .project-details {
    .project-details__header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        text-align: center;
        width: 100%;
        font-size: 1.5rem;
      }
    }
    .project-details__container {
      .project-details__left {
        .project-details__image-container {
          .project-details__image {
            width: 300px;
          }
        }
        .project-details__tech {
          ul {
            justify-content: center;
          }
          .project-details__tech-used {
            p {
              font-size: .8rem;
            }
          }
        }
      }
      .project-details__right {
        .project-details__summary {
          .project-details__summary-text {
            font-size: .8rem;
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 400px) {
  .project-details {
    .project-details__header {
      h1 {
        font-size: 1.2rem;
      }
    }
    .project-details__container {
      .project-details__left {
        .project-details__image-container {
          .project-details__image {
            width: 250px;
          }
        }
        .project-details__tech {
          ul {
            justify-content: center;
          }
          .project-details__tech-used {
            p {
              font-size: .7rem;
            }
          }
        }
      }
      .project-details__right {
        .project-details__summary {
          .project-details__summary-text {
            font-size: .7rem;
          }
        }
      }
    }
  }
}









