/* ProjectLinks.css */

@import '../../Styles/variables.scss';

.project-links {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  
  .project-links__container {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    width: 100%;
    border-radius: 10px;
  }
}

.project-link {
  margin: 0.5rem;
  padding: 0.8rem;
 
  border-radius: 5px;
  background-color: $btn;
  color: black;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  &:hover {
    background-color: $btn-hover;
    color: white;
  }
}

@media screen and (max-width: 950px) {
  .project-links {
    .project-links__container {
      width: 100%;
      .project-link {
        font-size: .8rem;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .project-links {
    .project-links__container {
      width: 100%;
      .project-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        width: 125px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .project-links {
    width: 100%;
    .project-links__container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      .project-link {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: .8rem;
        width: 100px;
      }
    }
  }
}
