/* Navbar.css */

@import '../../Styles/variables.scss';

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.navbar__container {
  display: flex;
  opacity: 85%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #f5f5f5;
  padding: 1rem;
  height: 12vh;
  opacity: 85%;
}

.navbar__logo {
  margin: 0;
  padding: 0;
}

.navbar__logo-image {
  width: 175px;
  height: 175px;
  padding: 0
}

.navbar__items {
  display: flex;
}

.navbar__item, .navbar__item > button {
  list-style: none;
  background-color: transparent;
  border: none;
  margin: 0 .5rem;
  padding: 0;
  font-size: 1.3rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

.navbar__item::marker {
  content: none;
}

.active {
  background-color: $btn;
  transition: background-color .3s ease-in-out;
}

@media screen and (max-width: 700px) {
  .navbar {
    .navbar__container {
      .navbar__logo {
        .navbar__logo-image {
          width: 100px;
          height: 100px;
        }
      }
      .navbar__items {
        display: flex;
        justify-content: center;
        align-items: center;
        .navbar__item {
          font-size: 1rem;
          button {
            font-size: 1rem;
            background-color: $btn;
            height: 50px;
            width: 100px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    height: 200px;
    .navbar__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .navbar__items__container {
        .navbar__items {
          .navbar__item {
            font-size: .8rem;
            button {
              font-size: .8rem;
              height: 40px;
              width: 80px;
            }
          }
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .navbar {
    height: 200px;
    .navbar__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .navbar__items__container {
        .navbar__items {
          .navbar__item {
            font-size: .65rem;
            button {
              background-color: transparent;
              font-size: .7rem;
              height: 40px;
              width: 50px;
            }
          }
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}