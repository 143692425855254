/* Contact.css */

@import '../../Styles/variables.scss';

.contact {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  h1 {
    font-size: 2rem;
    font-weight: 500;
  
  }
}

.contact__container {
  border: $border;
  padding: 2rem;
  width: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.contact__text {
  line-height: 2;
  margin: 1rem;
  text-align: center;
  p {
    font-size: 1rem;
  }

}

.contact__links {
  height: 100%;
  margin: 1rem 0;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  img {
    width: 35px;
    height: 35px;
    transition: transform .3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}



@media screen and (max-width: 850px) {
 .contact {
  .contact__container {
    .contact__links {
      width: 100%;
    }
  }
 }
}

@media screen and (max-width: 550px) {
  .contact {
    h1 {
      font-size: 1.5rem;
    }
    .contact__container {
      width: 60%;
      padding: 2rem;
      .contact__links {
        width: 100%;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .contact__text {
        padding: 0;
        .contact__text-paragraph {
          font-size: .8rem;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
  
