@import "./Styles/variables";
@import "./Styles/mixins";

html {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.app {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


